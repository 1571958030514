/*
* Need to add a 5rem spacer to normal pages to allow space for navbar
*/
body.standard {
  padding-top: 5rem;
}

/* Front Page Masthead Home */

body.masthead {
  padding-top: 0;
}


/*
* Override Bootstrap Pagination
*/
.pagination {
	justify-content: center;
 }

/* 
* Required to make SVG image fluid in IE
* These are used on search error, about us pages
*/
object {
  width: 100%;
}


/*
* Homepage Showcase
*/
// Showcase no longer used 
/*
.showcase .showcase-text {
    //padding: 3rem
    // padding-top: 50px;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
}
.showcase .showcase-img {
	//min-height: 2rem;
	background-size: cover
}
@media (min-width:768px) {
	.showcase .showcase-text {
		padding: 3rem
	}
}
*/

