/* Logos On Detail Page
--------------------------------*/

#detail-google,#detail-yahoo,#detail-bing,#detail-geekflare,#detail-bytecheck,#detail-gtmetrix,#detail-webpagetest,#detail-openlinkprofiler,#detail-archiveorg,#detail-netcraft {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  
  #detail-google:hover,#detail-yahoo:hover,#detail-bing:hover,#detail-geekflare:hover,#detail-bytecheck:hover,#detail-gtmetrix:hover,#detail-webpagetest:hover,#detail-openlinkprofiler:hover,#detail-archiveorg:hover,#detail-netcraft:hover {
    filter: none;
    -webkit-filter: grayscale(0);
  }

 /* Borders On Detail Page
--------------------------------*/

  .border-left-primary {
    border-left: 0.25rem solid #4e73df !important;
  }
  
  .border-bottom-primary {
    border-bottom: 0.25rem solid #4e73df !important;
  }
  
  .border-left-secondary {
    border-left: 0.25rem solid #858796 !important;
  }
  
  .border-bottom-secondary {
    border-bottom: 0.25rem solid #858796 !important;
  }
  
  .border-left-success {
    border-left: 0.25rem solid #1cc88a !important;
  }
  
  .border-bottom-success {
    border-bottom: 0.25rem solid #1cc88a !important;
  }
  
  .border-left-info {
    border-left: 0.25rem solid #36b9cc !important;
  }
  
  .border-bottom-info {
    border-bottom: 0.25rem solid #36b9cc !important;
  }
  
  .border-left-warning {
    border-left: 0.25rem solid #f6c23e !important;
  }
  
  .border-bottom-warning {
    border-bottom: 0.25rem solid #f6c23e !important;
  }
  
  .border-left-danger {
    border-left: 0.25rem solid #e74a3b !important;
  }
  
  .border-bottom-danger {
    border-bottom: 0.25rem solid #e74a3b !important;
  }
  
  .border-left-light {
    border-left: 0.25rem solid #f8f9fc !important;
  }
  
  .border-bottom-light {
    border-bottom: 0.25rem solid #f8f9fc !important;
  }
  
  .border-left-dark {
    border-left: 0.25rem solid #5a5c69 !important;
  }
  
  .border-bottom-dark {
    border-bottom: 0.25rem solid #5a5c69 !important;
  }
 
  /* Navs On Detail Page
--------------------------------*/
  nav > .nav.nav-tabs{

    border: none;
      color:#fff;
      background: $secondary;
      border-radius:0;
  
  }
  nav > div a.nav-item.nav-link,
  nav > div a.nav-item.nav-link.active
  {
    border: none;
      padding: 18px 25px;
      color:#fff;
      background: $primary;
      border-radius:0;
  }
  
  nav > div a.nav-item.nav-link.active:after
   {
    //content: "";
    //position: relative;
    //bottom: -60px;
    //left: -10%;
    //border: 15px solid transparent;
    border-top-color: $primary;
  }

  .tab-content{
    background: #fdfdfd;
      line-height: 25px;
      border: 1px solid #ddd;
      border-top:5px solid $primary;
      border-bottom:5px solid $primary;
      padding:30px 25px;
  }
  
  nav > div a.nav-item.nav-link:hover,
  nav > div a.nav-item.nav-link:focus
  {
    border: none;
      background: $secondary;
      color: $primary;
      border-radius:0;
      //transition:background 0.20s linear;
  }

  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 11rem;
    z-index: 990;
}