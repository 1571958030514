.cat-description {
    //padding: 40px;
    margin-bottom: 40px;
    font-weight: 700;
    color: $logospan;
}


/* simplelinkw6 Section
--------------------------------*/

#simplelinkw6 {
    padding: 30px 0 0 0;
}
  
#simplelinkw6 .box {
    padding: 40px;
    margin-bottom: 40px;
    box-shadow: 10px 10px 15px rgba(73, 78, 92, 0.1);
    background: #fff;
    transition: 0.4s;
}
  
#simplelinkw6 .box:hover {
    box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
}
  
#simplelinkw6 .box h4 {
    margin-left: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 22px;
}
  
#simplelinkw6 .box h4 a {
    color: #444;
}
  
#simplelinkw6 .box h2 {
    color: $primary;
    margin-left: 20px;
    margin-bottom: 10;
}
  
#simplelinkw6 .box p {
    font-size: 14px;
    margin-left: 20px;
    margin-bottom: 0;
    line-height: 24px;
}

// Bullets for Sitemap
.nobull {
    list-style-type: none;
}
.nobull h2 a{
    font-weight: bold;
}

//This gives padding to the category bottom
.ex1 {
    padding-bottom: 50px;
  }