/* Category Icons on Home Page */

.iconfront ul {
    padding: 0;
	list-style: none;
	margin: 30px auto;
}
.iconfront ul li {
    display: inline-block;
	margin: 10px 10px 0 0;
}
.iconfront ul li a {
    padding: 1rem;
    background-color: $primary;
    border-radius: 50%;
    text-align: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
    display: inline-block;
    line-height:0px;
}
.iconfront ul li a i {
    color: $hlhover;
	-webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition:  0.5s;
}
.iconfront ul li a:hover i {
	-webkit-transform: rotateZ(360deg);
	-moz-transform: rotateZ(360deg);
	transform: rotateZ(360deg);
}


/*
* Custom Font Awesome Icons
*/

.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .feature-item .feature-icon {
    //color: rgb(255, 255, 255);
    text-align:center;
  }

.fa-automotive:before{content:"\f1b9"}
.fa-business-and-finance:before{content:"\f275"}
.fa-computers-and-the-internet:before{content:"\f109"}
.fa-entertainment-and-media:before{content:"\f001"}
.fa-health-and-fitness:before{content:"\f0f9"}
.fa-hobbies-and-interests:before{content:"\f1fc"}
.fa-home-and-family:before{content:"\f0c0"}
.fa-people-and-chat:before{content:"\f182"}
.fa-reference-and-education:before{content:"\f19d"}
.fa-shopping-and-services:before{content:"\f291"}
.fa-society-and-politics:before{content:"\f0e3"}
.fa-sports-and-recreation:before{content:"\f206"}
.fa-telecommunications:before{content:"\f095"}
.fa-the-counties:before{content:"\f277"}
.fa-tourism-and-travel:before{content:"\f1e5"}
.fa-travel-and-holidays:before{content:"\f072"}
