// Variables

//- Override and add to color system

$orange: #f4623a !default;
$brown: #5c4d42 !default;
$white: #ffffff !default;

//- Override primary color
//$primary: $orange !default;
//$primary: #4D648D !default;
//$secondary: #D0E1F9 !default;

$primary: #404756 !default;
$secondary: #a4abbd !default;
$hlhover: #c9deff;
$softgray: #dddede;
$headerblue: #b2c7f6;
$logospan: #4d648d;

/*
https://mycolor.space/?hex=%234D648D&sub=1
Grey Friends
*/
$one: #4D648D;
$two: #404756;
$three: #A4ABBD;
$four: red;

//Shades
$five: #4D648D;
$six: #6B81AC;
$seven: #899FCC;
$eight: #A9BEEC;
$nine: #C9DEFF;

//Random Shades
$ten: #4D648D;
$eleven: #B2C7F6;
$twelve: #4D648D;
$thirteen: #2C456C;
$fourteen: #364F76;



//- Override font system
//$font-family-sans-serif: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
//$font-family-serif: "Merriweather", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
//$font-family-base: $font-family-serif !default;
//$headings-font-family: $font-family-sans-serif !default;

// Override Bootstrap font variables
$font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
$font-family-serif: "Maven Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
$headings-font-family: "Maven Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
$font-family-base: $font-family-serif !default;
$headings-font-family: $font-family-sans-serif !default;



//- Add custom variables
$navbar-height: 4.5rem !default;