body.masthead {
    padding-top: 0;
}

/* Header Parallax Element Style*/ 
.paral {
    min-height: 600px;
    background-attachment: fixed;
    background-size: cover;
    background-position: 50% 50%;
    //border-radius: 0px;
}
    
/* Paragrapgh for Parallax Section */ 
.paral p {
    font-size: 24px;
    color:#f5f5f5;
    text-align: center;
    text-shadow: 1px 1px #333;
    //line-height: 60px;
}
    
/* Heading for Parallax Section */ 
.paral h2 {
    color: rgba(255, 255, 255, 0.8);
    font-size: 60px;
    text-align: center;
    padding-top: 60px;
    line-height: 100px;
    text-shadow: 1px 1px #333;
}

/* Remove Bottom Margin from Jumbotron */
.jumbotron{
    margin-bottom: 0;
}
